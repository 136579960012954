import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import Navmenu from './NavMenu'



const Header7 = () => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute right-[3%] left-[3%] z-[15] px-4 lg:px-6 mb-6 '>
            <div className='flex mx-auto justify-center'>
                {/* <div className='w-full mr-0 md:w-[75%] lg:w-[25%] lg:mr-10 mt-0 lg:-mt-[30px] mb-0 lg:-mb-[50px] rounded-lg bg-black rounded-2xl'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[70%] mx-auto pt-3 pb-5 '
                    />
                </div> */}

                <div className='block w-[50%] lg:w-full self-center'>
                    <div className='flex'>
                        <div className='w-[25%] lg:w-[37%] hidden md:flex items-center justify-start'>
                            {/* <nav>
                                <Navmenu />
                            </nav> */}
                        </div>
                        <div className='w-full mr-0 md:w-[75%] lg:w-[40%] lg:-mt-[30px] mb-0 lg:-mb-[50px] bg-[#000000] rounded-2xl'>
                            <img
                                src={rpdata?.dbPrincipal?.logo}
                                alt='logo'
                                loading='lazy'
                                className='w-[70%] mx-auto pt-3 pb-5 '
                            />
                        </div>
                        <div className=' hidden lg:block w-[45%] lg:w-[37%]'>
                        </div>
                    </div>
                </div>

            </div>
        </header>
    )
}

export default Header7
